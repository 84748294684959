/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import kvglogo from "./logoblue.svg"

const Layout = ({ children, mainclass }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main className={mainclass}>{children}</main>
      <footer>
        <div className="center">
          <div>
            <img src={kvglogo} alt="Hankintaverkko logo" />
            <h3>Hankintaverkko.fi</h3>
            <p>
              Hankintaverkon tehtävä on tarjota ratkaisu tarpeeseenne
              vaativissakin suojavarustehankinnoissa, joten pyydäthän tarjouksen
              mistä vain PPE tuotteesta.
            </p>
          </div>

          <p className="copy">
            <small>Hankintaverkko © {new Date().getFullYear()}</small>
          </p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
