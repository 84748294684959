import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import classnames from "classnames"
import "./header.css"
import kvglogo from "./logowhite.svg"

const Header = ({ siteTitle }) => {
  const [menu, toggleMenu] = useState(false)
  useEffect(() => {
    document.body.style["max-height"] = menu ? "100vh" : null
    document.body.style["overflow"] = "hidden"
  }, [menu])
  return (
    <header className={classnames({ open: menu })}>
      <div className="nav-container">
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
              display: "flex",
              alignContent: "center",
            }}
          >
            <img
              src={kvglogo}
              style={{ marginRight: "1rem", marginBottom: 0, width: "75px" }}
              alt="Hankintaverkko logo"
            />
            <span style={{ alignSelf: "center" }}>{siteTitle}</span>
          </Link>
        </h1>
        <div
          onClick={() => toggleMenu(!menu)}
          className={classnames(
            "hamburger",
            "hamburger--spin",
            "js-hamburger",
            { "is-active": menu }
          )}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
        <div className={classnames("nav", { open: menu })}>
          <Link to="/" onClick={() => toggleMenu(false)}>
            Etusivu
          </Link>
          <Link to="/tuotteet/" onClick={() => toggleMenu(false)}>
            Tuotteet
          </Link>
          <Link
            to="/yhteystiedot/"
            className="btn"
            onClick={() => toggleMenu(false)}
          >
            Ota yhteyttä
          </Link>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
